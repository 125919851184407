.textInputContainer {
    padding-top: 10px;
    font-family: 'Open Sans';
    font-size: 16px;
    color: #282E3B;
}

.textInputBox {
    margin-top: 10px;
    padding: 10px;
    text-align: left;
    font-style: normal;
    font-size: 14px;
    line-height: 24px;
    border: solid 1px #ccc;
    width: 100%;
}
