.radio-input-question-container {
    width: 100%;
    height: 100%;
    padding-top: 24px;
    display: flex;
    flex-direction: column;
}

.radio-question-p {
    font-family: 'Open Sans';
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    color: #282E3B;
}

.radio-input-answer-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #666666;
}

.radio-input-answer-label {
    height: 24px;
}

.radio-input-answer-btn {
    height: 24px;
}

.radio-question-error-p {
    color: red;
    font-weight: bold;
}
