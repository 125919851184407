.informationPanelComponent {
    line-height: 24px;
    padding: 10px;
    width: 100%;
    font-size: 16px;
    color: #666666;
    margin: 10px 0;
}

.informationPanelWithBackground {
    background-color: var(--information-panel-background);
}

.text-align-center {
    text-align: center;
}

.text-align-left {
    text-align: left;
}
