.emptySpaceForFixedButtons {
    display:block;
    visibility: hidden;
}

.two-buttons-height {
    height: 130px;
}

.three-buttons-height {
    height: 190px;
}
