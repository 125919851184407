.btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 50px;
    border-radius: 10px;
    font-weight: bold;
    font-size: 16px;
    font-family: Montserrat-bold;
    line-height: 1.2;
    letter-spacing: 1px;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
    padding: 0 20px;
    margin-top: 10px;
}

.primaryBtn {
    background-color: rgb(13, 132, 38);
    color: rgb(255, 255, 255);
}

.primaryBtn:disabled {
    background-color: rgba(13, 132, 38, 0.4);
    color: rgba(255, 255, 255, 0.8);
}

.secondaryBtn {
    background-color: rgb(238, 238, 238);
    color: rgb(141, 141, 141);
}

.secondaryBtn:disabled {
    background-color: rgba(238, 238, 238, 0.4);
    color: rgba(141, 141, 141, 0.5);
}

.dangerBtn {
    background-color: rgb(195, 57, 76);
    color: rgb(255, 255, 255);
}

.dangerBtn:disabled {
    background-color: rgba(195, 57, 76, 0.3);
    color: rgba(255, 255, 255, 0.8);
}
