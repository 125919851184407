.confirm-modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    min-height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 1;
}

.confirm-modal-main {
    position:fixed;
    background: white;
    width: 80%;
    height: 250px;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    border-radius: 10px;
}

.confirm-modal-title {
    font-size: 30px;
    font-weight: 900;
    text-align: left;
    line-height: 32px;
    letter-spacing: -1.28px;
    color: #0d8426;
    font-family: "Poppins", Arial, sans-serif;
}

.confirm-modal-text {
    line-height: 24px;
    width: 100%;
    font-size: 16px;
    color: #666666;
    margin: 10px 0;
    text-align: left;
}

.confirm-modal-button-container {
    display: flex;
    justify-content: space-around;
}

.confirm-modal-button {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 50px;
    border-radius: 10px;
    font-weight: bold;
    font-size: 16px;
    font-family: Montserrat-bold;
    line-height: 1.2;
    letter-spacing: 1px;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.confirm-modal-continue-button {
    background-color: rgb(13, 132, 38);
    color: rgb(255, 255, 255);
    margin-left: 5px;
}

.confirm-modal-cancel-button {
    background-color: rgb(238, 238, 238);
    color: rgb(141, 141, 141);
    margin-right: 5px;
}
