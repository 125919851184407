#decommission-summary-container {
  background-color: var(--information-panel-background);
  padding: 12px;
}

.informationSubHeading {
  color: #000;
  font-size: 16px;
  font-family: 'Open Sans';
  font-weight: 700;
  line-height: 1.4;
}