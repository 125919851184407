/* first div*/
.btn-container {
    overflow: hidden;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    width: 100%;
    flex-direction: column;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 0 30px 10px 30px;
    background-color: white;
}
